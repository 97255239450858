import gql from 'graphql-tag';
import { PAGE_META_TYPE_FRAGMENT } from './page-meta';
import { SPECIALITY_FRAGMENT } from './speciality';

export const POLE_FRAGMENT = gql`
  fragment PoleFragment on Pole {
    id
    name
    contact
    addressStreet
    addressPostalCode
    addressCity
  }
`;

export const POLE_FIND_MANY = gql`
  query PoleFindMany(
    $take: Int
    $skip: Int
    $where: PoleWhereInput
    $orderBy: [PoleOrderByWithRelationInput!]
  ) {
    PoleFindMany(
      take: $take
      skip: $skip
      where: $where
      orderBy: $orderBy
    ) {
      meta {
        ...PageMetaTypeFragment
      }
      items {
        ...PoleFragment
        Pole2Speciality {
          isActive
          price
          specialityId
          speciality {
            ...SpecialityFragment

          }
        }
      }
    }
  }
    ${POLE_FRAGMENT},
    ${SPECIALITY_FRAGMENT},
    ${PAGE_META_TYPE_FRAGMENT}
`;

export const POLE_FIND_ONE = gql`
  query PoleFindUnique($poleFindUniqueId: Int!) {
    PoleFindUnique(id: $poleFindUniqueId) {
      ...PoleFragment
      Pole2Speciality {
        isActive
        price
        specialityId
        speciality {
          ...SpecialityFragment
        }
      }
    }
  }
  ${POLE_FRAGMENT}
  ${SPECIALITY_FRAGMENT}
`;

export const POLE_CREATE = gql`
  mutation PoleCreate($input: PoleCreateInput!, $specialitiesId: [Int!]!) {
    PoleCreate(input: $input, specialitiesId: $specialitiesId) {
      ...PoleFragment
    }
  }
  ${POLE_FRAGMENT}
`;

export const POLE_UPDATE = gql`
  mutation PoleUpdate(
    $poleUpdateId: Int!
    $input: PoleUpdateInput!
    $specialitiesId: [Int!]!
  ) {
    PoleUpdate(
      id: $poleUpdateId
      input: $input
      specialitiesId: $specialitiesId
    ) {
      ...PoleFragment
    }
  }
  ${POLE_FRAGMENT}
`;
