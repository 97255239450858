import gql from 'graphql-tag';
import {PAGE_META_TYPE_FRAGMENT} from './page-meta';
import {POLE_FRAGMENT} from './pole';
import {SPECIALITY_FRAGMENT} from './speciality';

export const EMPLOYEE_FRAGMENT = gql`
  fragment EmployeeFragment on Employee {
    addressCity
    addressPostalCode
    addressStreet
    contact
    dateOfBirth
    email
    iban
    id
    name
    identificationNumber
    identificationNumberValidity
    niss
    orderNumber
    vatNumber
  }
`;

export const EMPLOYEE_FIND_MANY = gql`
  query EmployeeFindMany(
    $take: Int
    $skip: Int
    $orderBy: [EmployeeOrderByWithRelationInput!]
    $where: EmployeeWhereInput
  ) {
    EmployeeFindMany(
      take: $take
      skip: $skip
      orderBy: $orderBy
      where: $where
    ) {
      items {
        ...EmployeeFragment
        Employee2Speciality {
          isActive
          speciality {
            ...SpecialityFragment
          }
        }
        Employee2Pole {
          isActive
          pole {
            ...PoleFragment
          }
        }
      }
      meta {
        ...PageMetaTypeFragment
      }
    }
  }
    ${EMPLOYEE_FRAGMENT},
    ${SPECIALITY_FRAGMENT},
    ${PAGE_META_TYPE_FRAGMENT},
    ${POLE_FRAGMENT}
`;

export const EMPLOYEE_FIND_ONE = gql`
  query EmployeeFindUnique($employeeFindUniqueId: Int!) {
    EmployeeFindUnique(id: $employeeFindUniqueId) {
      ...EmployeeFragment
      Employee2Speciality {
        isActive
        speciality {
          ...SpecialityFragment
        }
      }
      Employee2Pole {
        isActive
        pole {
          ...PoleFragment
        }
      }
    }
  }
    ${EMPLOYEE_FRAGMENT},
    ${SPECIALITY_FRAGMENT},
    ${POLE_FRAGMENT}
`;

export const EMPLOYEE_CREATE = gql`
  mutation EmployeeCreate(
    $input: EmployeeCreateInput!
    $polesId: [Int!]!
    $specialitiesId: [Int!]!
  ) {
    EmployeeCreate(
      input: $input
      polesId: $polesId
      specialitiesId: $specialitiesId
    ) {
      ...EmployeeFragment
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;

export const EMPLOYEE_UPDATE = gql`
  mutation EmployeeUpdate(
    $employeeUpdateId: Int!
    $input: EmployeeUpdateInput!
    $polesId: [Int!]!
    $specialitiesId: [Int!]!
  ) {
    EmployeeUpdate(
      id: $employeeUpdateId
      input: $input
      polesId: $polesId
      specialitiesId: $specialitiesId
    ) {
      ...EmployeeFragment
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;
