import { Injectable } from '@angular/core';
import { EmployeeType } from '@core/types/employee.type';
import { Paginated } from '@core/types/paged-type';
import { normalizeUpdateInputForPrisma } from '@targx/utils/utils';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, of, switchMap } from 'rxjs';
import {
  EMPLOYEE_CREATE,
  EMPLOYEE_FIND_MANY,
  EMPLOYEE_FIND_ONE,
  EMPLOYEE_UPDATE,
} from '../graphql/employee';

class PaginatedEmployeeFindAll extends Paginated(EmployeeType) { }

@Injectable({ providedIn: 'root' })
export class EmployeesService {
  private _apollo = this.apollo.use('managment');
  constructor(private readonly apollo: Apollo) { }

  private _employees: BehaviorSubject<EmployeeType[]> = new BehaviorSubject<
    EmployeeType[]
  >([]);

  private _employee: BehaviorSubject<EmployeeType | null> =
    new BehaviorSubject<EmployeeType | null>(null);

  get employees$() {
    return this._employees.asObservable();
  }

  get employee$() {
    return this._employee.asObservable();
  }

  findAll(args?: { take?: number; skip?: number }) {
    return this.apollo
      .watchQuery<{ EmployeeFindMany: PaginatedEmployeeFindAll }>({
        query: EMPLOYEE_FIND_MANY,
        variables: {
          take: args?.take,
          skip: args?.skip,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        switchMap(result => {
          if (result.errors) throw new Error(result.errors[0].message);

          const employees = result.data.EmployeeFindMany.items;

          this._employees.next(employees);
          return of(employees);
        })
      );
  }

  findUnique(id: number) {
    return this.apollo
      .watchQuery<{ EmployeeFindUnique: EmployeeType }>({
        query: EMPLOYEE_FIND_ONE,
        variables: {
          employeeFindUniqueId: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        switchMap(result => {
          if (result.errors) throw new Error(result.errors[0].message);

          const employee = result.data.EmployeeFindUnique;

          this._employee.next(employee);
          return of(employee);
        })
      );
  }

  create(input: { [key: string]: any; specialities: number[]; poles: number[] }) {
    const { specialities, poles, ...rest } = input;

    return this._apollo
      .mutate<{ EmployeeCreate: EmployeeType }>({
        mutation: EMPLOYEE_CREATE,
        variables: {
          input: rest,
          specialitiesId: specialities,
          polesId: poles,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        switchMap(result => {
          if (result.errors) throw new Error(result.errors[0].message);

          const employee = result.data.EmployeeCreate;

          this._employee.next(employee);
          return of(employee);
        })
      );
  }

  update(
    id: number,
    input: { [key: string]: any; specialities: number[]; poles: number[] }
  ) {
    const { specialities, poles, ...rest } = input;
    return this._apollo
      .mutate<{ EmployeeUpdate: EmployeeType }>({
        mutation: EMPLOYEE_UPDATE,
        variables: {
          employeeUpdateId: id,
          input: normalizeUpdateInputForPrisma(rest),
          specialitiesId: specialities,
          polesId: poles,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        switchMap(result => {
          if (result.errors) throw new Error(result.errors[0].message);

          const employee = result.data.EmployeeUpdate;

          this._employee.next(employee);
          return of(employee);
        })
      );
  }
}
