import {AppointmentType} from './appointment.type';
import {Client2SpecialistType} from './client-2-specialist.type';
import {Employee2PoleType} from './employee-2-pole.type';
import {Employee2SpecialityType} from './employee-2-speciality.type';
import {EmployeeAvailabilityType} from './employee-availability.type';
import {EmployeeTypeType} from './employee-type.type';
import {UserType} from './user.type';

export class EmployeeType {
  id: number;
  name: string;
  addressStreet: string;
  addressPostalCode: string;
  addressCity: string;
  contact: string;
  dateOfBirth: Date;
  email: string;
  vatNumber: string;
  niss?: string;
  identificationNumber?: string;
  identificationNumberValidity?: Date;
  orderNumber?: string;
  iban?: string;
  typeId: number;
  type: EmployeeTypeType;
  User?: UserType;

  Appointment?: AppointmentType[];
  Employee2Pole?: Employee2PoleType[];
  Employee2Speciality?: Employee2SpecialityType[];
  Client2Specialist?: Client2SpecialistType[];
  EmployeeAvailability?: EmployeeAvailabilityType[];
}
